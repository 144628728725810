.hero {
  padding-top: 85px;

  @media only screen and (max-width: 992px) {
    padding-bottom: 50px;
  }

  h2 {
    margin: 82px 0;
    padding: 0 90px;
    font-size: 50px;
    line-height: 67px;
    font-weight: 400;

    @media only screen and (max-width: 992px) {
      font-style: italic;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;
      margin: 25px 0;
      padding: 0 25px;
    }
  }

  .col-6 {
    .project {
      margin-bottom: 30px;
    }
  }

  .content {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;

    .content-overlay {
      background: rgba(0,0,0,0.7);
      position: absolute;
      height: calc(100% - 7px);
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: all 0.4s ease-in-out 0s;

      @media only screen and (max-width: 992px) {
        display: none;
      }

      & + span {
        width: 100%;
      }
    }

    &:hover {
      .content-overlay{
        opacity: 1;

        @media only screen and (max-width: 992px) {
          display: none;
        }
      }
    }
  }

  .content-image {
    width: 100%;
  }

  .content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out 0s;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .content-details h3 {
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;

    @media only screen and (max-width: 992px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .fadeIn-bottom {
    top: 80%;
  }

  .project {
    margin-bottom: 90px;

    @media only screen and (max-width: 992px) {
      margin-bottom: 30px;
    }

    img {
      display: block;
      width: 100%;
    }

    &.video {
      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  .col-lg-4 {
    h3 {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 30px;
    }
  }
}
