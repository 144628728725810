.contact {
  header {
    .layoutToggler {
      display: none;
    }

    .nav-link.active {
      color: $black!important;
      text-decoration: underline;
    }

    button.active {
      color: $black!important;
    }

    .navbar-toggler {
      p {
        color: #222222;
      }
    }
  }

  &__inner {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 992px) {
      padding-top: 6rem !important;
    }

    .col-lg-6 {
      &:first-child {
        @media only screen and (max-width: 992px) {
          padding-bottom: 2rem;
        }
      }
    }

    h1 {
      font-family: 'suisse_intlregular';
      font-style: normal;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 60px;

      @media only screen and (max-width: 992px) {
        font-size: 45px;
      }
    }

    h3 {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;

      @media only screen and (max-width: 992px) {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
      }

      a {
        color: #222222;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .phone {
      a {
        &:hover {
          @media only screen and (min-width: 992px) {
            text-decoration: none;
            pointer-events: none;
            cursor: default;
          }
        }
      }
    }

    p {
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        color: inherit;
        text-decoration: none;

        @media only screen and (max-width: 992px) {
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          display: block;
        }

        &:hover {
          text-decoration: underline !important;
          cursor: pointer;
        }
      }
    }
  }

  footer {
    text-align: center;

    p {
      color: $black;
    }
  }
}
