.changeLayout {
  @media only screen and (max-width: 992px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 12px 0;
  }
}

.layoutToggler {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background: transparent;
    border: 0;
    margin: 0 10px;
    transform: scale(.9);

    &:first-child {
      span {
        display: block;
        border: 1px solid black;
        width: 42px;
        height: 25px;
        border-radius: 1px;
      }
    }

    &:last-child {
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 42px;
        height: 25px;

        span {
          display: block;
          border: 1px solid black;
          width: 45%;
          height: 10px;
          border-radius: 1px;
        }
      }
    }

    &.active {
      transform: scale(1.25);

      &:first-child {
        span {
          background: black;
          transition: all .2s ease;
        }
      }

      &:last-child {
        transform: scale(1.25);

        span {
          span {
            background: black;
            transition: all .2s ease;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .only-mobile {
    & + footer {
      display: none;
    }
  }
}
