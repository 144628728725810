.project {
  header {
    .layoutToggler {
      display: none;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }

  .only-mobile {
    h1 {
      text-align: right;
      margin-bottom: 2rem;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;

      a {
        text-decoration: none;
        color: inherit;

        img {
          width: 19px;
          height: 19px;
          transition: all .25s ease;
          display: inline-block;
          margin-left: 7px;
        }

        &[aria-expanded="true"] {
          img {
            transform: rotate(45deg);
            transition: all .25s ease;
          }
        }
      }
    }
  }

  &__inner {
    margin-top: 80px;
    padding-top: 80px;

    @media only screen and (max-width: 992px) {
      margin-top: 50px;
      padding-top: 50px;
    }

    .row {
      & > * {
        padding-right: calc(var(--bs-gutter-x) * 0.75);
        padding-left: calc(var(--bs-gutter-x) * 0.75);
      }

      & > .col-lg-12 {
        & > div {
          width: 100% !important;
          max-width: 100% !important;
          height: 100% !important;

          div {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      &.mb-3.pb-3 {
        .col-lg-12.mb-3.pb-3 {
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
        }
      }

      .collapse {
        padding: 0;
      }

      .collapsing {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    img {
      display: block;
      width: 100%;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
    }

    &--footer {
      padding-top: 50px;

      div {
        margin-bottom: 2rem;

        p {
          margin-bottom: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .changeLayout {
    display: none;
  }

  footer {
    display: block;
  }
}
