.about {
  overflow-x: hidden;

  h1 {
    font-style: normal;
    text-transform: uppercase;
  }

  h5 {
    text-transform: uppercase;
  }

  header {
    .layoutToggler {
      display: none;
    }
  }

  .title {
    padding-top: 50px;
    margin-bottom: 50px;
  }

  .intro {
    margin-bottom: 70px;

    div {
      & + br {
        display: none;
      }

      p {
        & + br {
          display: none;
        }
      }
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  .members {
    margin-bottom: 50px;

    img {
      display: block;
      width: 100%;
      margin-bottom: 16px;
    }

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin: 0;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .partners {
    margin-bottom: 70px;

    .title {
      text-align: center;
    }

    .slider {
      text-align: center;

      @media only screen and (max-width: 1024px) {
        padding: 0 50px;
      }

      .col {
        text-align: center;

        @media only screen and (max-width: 1024px) {
          width: 5rem !important;
          height: 6rem !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          margin: 0 auto;
        }

        img {
          display: block;
          margin: 0 auto;
          width: auto;
          max-width: 100%;
        }
      }

      .slick-prev,
      .slick-next {
        width: 30px;
        height: 35px;

        @media only screen and (max-width: 768px) {
          transform: scale(1.15);
        }
      }

      .slick-prev {
        left: -50px;
        z-index: 50;
        transform: scale(1.25);
        margin-top: -70px;

        &:before {
          content: url('../../images/arrow-left.svg');
          font-size: 1rem;
        }

        @media only screen and (max-width: 768px) {
          left: 10px;
          margin-top: -20px;
        }
      }

      .slick-next {
        right: -50px;
        z-index: 50;
        transform: scale(1.25);
        margin-top: -70px;

        &:before {
          content: url('../../images/arrow-right.svg');
        }

        @media only screen and (max-width: 768px) {
          right: 10px;
          margin-top: -20px;
        }
      }
    }
  }

  .list {
    h5 {
      margin-bottom: 55px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      text-transform: uppercase;
    }

    &__inner {
      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 20px;

          p {
            margin: 0;

            strong {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
      }

      @media only screen and (max-width: 768px) {
        margin-bottom: 4rem;
      }
    }
  }

  .changeLayout {
    display: none;
  }

  footer {
    display: block;
  }

  .only-mobile {
    h1 {
      margin-bottom: 2rem;
      text-align: right;

      a {
        text-decoration: none;
        color: inherit;

        img {
          width: 19px;
          height: 19px;
          transition: all .25s ease;
          display: inline-block;
          margin-left: 7px;
        }

        &[aria-expanded="true"] {
          img {
            transform: rotate(45deg);
            transition: all .25s ease;
          }
        }
      }
    }
  }

  .only-desktop {
    display: flex;
  }
}
