.services {
  min-height: 65vh;

  .row {
    &.only-mobile {
      h2 {
        font-family: 'suisse_intlregular';
        font-style: normal;
        font-weight: 300;
        font-size: 38px;
        line-height: 48px;
        text-transform: uppercase;
        color: #000000;
      }
    }
  }

  header {
    .layoutToggler {
      display: none;
    }
  }

  .and {
    margin-left: -10px;

    img {
      height: 50px;

      @media only screen and (max-width: 576px) {
        height: 43px;
      }
    }
  }

  .plus {
    margin-right: -10px;

    img {
      height: 60px;

      @media only screen and (max-width: 576px) {
        height: 50px;
      }
    }
  }

  .servicelist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;

    @media only screen and (max-width: 992px) {
      min-height: auto;
    }

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 65vh;

      @media only screen and (max-width: 992px) {
        min-height: auto;
        height: 100%;
        justify-content: flex-start;
      }

      li {
        list-style: none;

        span {
          display: inline-block;
        }

        &.active {
          a {
            text-decoration: underline;

            @media only screen and (max-width: 992px) {
              text-decoration: none;
            }
          }
        }

        &:nth-child(1) {
          @media only screen and (max-width: 992px) {
            margin-bottom: 8.5rem;
          }
        }

        &:nth-child(2) {
          @media only screen and (max-width: 992px) {
            margin-bottom: 8.5rem;
          }
        }
      }

      a {
        font-family: 'suisse_intlregular';
        color: inherit;
        text-decoration: none;
        font-style: normal;
        font-weight: 300;
        font-size: 70px;
        line-height: 100px;
        text-transform: uppercase;

        @media only screen and (max-width: 992px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }

  .serviceitems {
    .tab-content {
      position: relative;

      @media only screen and (max-width: 992px) {
        position: static;
        top: unset;
      }
    }

    .tab-pane {
      min-height: 65vh;
      position: relative;

      @media only screen and (max-width: 992px) {
        display: block;
        min-height: 8.5rem;
        margin-bottom: 2rem;
      }

      & > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
      }
    }

    p {
      font-family: 'Playfair Display';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 43px;
      margin: 0;

      @media only screen and (max-width: 992px) {
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  .changeLayout {
    display: none;
  }

  footer {
    display: block;
  }
}
