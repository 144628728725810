header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  padding: 12px 0;
  background: white;

  @media only screen and (max-width: 1250px) {
    padding: 12px 50px 12px 0;
  }

  .menu {
    display: flex;
  }

  .navbar-collapse {
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 992px) {
      width: 100%;
      background: blue;
      position: fixed;
      z-index: 9;
      display: block;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s;

      &.show {
        min-height: 100vh;
        visibility: visible;
        opacity: 1;
        z-index: 9;

        ul {
          li {
            opacity: 1;

            &:nth-child(1) {
              transition-delay: 0.06s;
            }

            &:nth-child(2) {
              transition-delay: 0.12s;
            }

            &:nth-child(3) {
              transition-delay: 0.18s;
            }

            &:nth-child(4) {
              transition-delay: 0.24s;
            }
          }
        }
      }
    }
  }

  .container {
    position: relative;
  }

  .navbar {
    .row {
      width: 100% !important;
      margin: 0 auto;

      .col-lg-5 {
        &:nth-child(1) {
          padding-left: 0;
        }

        &:nth-child(3) {
          padding-right: 0;
        }
      }

      .navbar-nav {
        @media only screen and (max-width: 992px) {
          padding-top: 4rem;
          height: 75vh;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .nav-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 8px;

        @media only screen and (max-width: 1170px) {
          margin: 0;
        }

        @media only screen and (max-width: 992px) {
          margin: 18px 10px;
        }

        &:last-child {
          margin-left: 32px;
          margin-right: 0;

          @media only screen and (max-width: 1170px) {
            margin-left: 12px;
          }

          button {
            padding: 0 2px;
            color: #222222;
            opacity: 0.5;

            @media only screen and (max-width: 992px) {
              font-weight: 400;
              font-size: 25px;
              line-height: 25px;
              display: block;
              margin: 30px 0;
              padding: 0;
            }
          }

          @media only screen and (max-width: 992px) {
            margin: 30px 10px;
            display: block;
          }
        }

        .nav-link {
          font-family: 'suisse_intlregular';
          color: $black;
          opacity: .5;
          transition: all .25s ease;
          cursor: pointer;

          @media only screen and (max-width: 992px) {
            font-weight: 400;
            font-size: 25px;
            line-height: 25px;
            color: #222222;
            opacity: 0.5;
          }

          &:hover {
            color: $black;
            opacity: 1;
            text-decoration: underline;
            transition: all .25s ease;
          }
        }

        .nav-link.active {
          color: $black;
          opacity: 1;
          text-decoration: underline;
        }

        button {
          border: 0;
          color: $grey;
          background: transparent;
          font-family: 'suisse_intlregular';
          text-transofm: uppercase;

          @media only screen and (max-width: 992px) {
            color: #222222;
            opacity: 0.5;
          }
        }

        button.active {
          color: #222222;
          opacity: 1;
          text-decoration: underline;
        }
      }
    }

    .navbar-collapse {
      @media only screen and (max-width: 992px) {
        background-color: #ffffff;
        padding-top: 4rem;
      }
    }

    .navbar-toggler {
      @media only screen and (max-width: 992px) {
        position: fixed;
        top: 23px;
        right: 50px;
        z-index: 20;
        font-family: 'suisse_intlregular';
        outline: none !important;
        border: 0 !important;
        box-shadow: none !important;
        align-items: center !important;
        justify-content: flex-end !important;
        padding: 0 !important;
        margin-right: -30px;

        &:focus {
          outline: none !important;
          border: 0 !important;
          box-shadow: none !important;
        }

        p {
          margin: 0;
          display: inline-block;
          color: #222;

          img {
            width: 19px;
            height: 19px;
            transition: all .25s ease;
            display: inline-block;
            margin-left: 7px;
          }
        }

        &[aria-expanded="true"] {
          img {
            transform: rotate(45deg);
            transition: all .25s ease;
          }
        }
      }
    }
  }

  .logo {
    cursor: pointer;

    @media only screen and (max-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    a {
      padding: 0;
      display: inline-block;

      img {
        @media only screen and (max-width: 992px) {
          width: 90px;
          display: block;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      position: relative;
      z-index: 20;
    }
  }
}
